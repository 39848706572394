export const trans = {
  facebookLanguage: 'nl_BE',
  title: 'Stockoma',
  categories: 'Categorieën',
  catalog: 'Catalogus',
  corona: {
    order: 'Bestellen',
    mailUs: 'Mail ons jouw verlanglijstje en haal je pakket bij ons op of laat het bij je thuis leveren!',
    title: 'Bestel uit onze catalogus',
    wayOfWorking: 'Geef ons jouw bestelling via email door en we confirmeren de beschikbaarheid van de artikelen zo snel mogelijk.',
    wayOfWorking2 : 'Terwijl we je pakket klaarstomen sturen we je ook de betaling- en verzendings voorwaarden.',
    pickupTitle: 'Afhaalservice',
    pickup: 'We maken jouw pakket klaar en laten je weten wanneer je het aan onze winkel kan komen ophalen.',
    deliveryTitle: 'Bestelservice',
    delivery: 'Alles wordt geleverd via GLS: €8,5 verzendingskosten in België.',
    noWeapons: 'Opgelet! Airguns, wapens en dergelijke kunnen niet besteld worden.',
    footer: 'of maak gebruik van onze',
  },
  corona_banner:{
    title: 'Maatregelen rondom Coronavirus!',
    paragraphOne: 'Vanaf 27/03 tot en met 26/04 nieuwe openingsuren',
    paragraphTwo: '(VAN 10.00 tot 12.00 en van 13.30 tot 18.00) op afspraak, een aankoop is niet nodig u mag ons gerust bezoeken voor de sfeer!',
    paragraphThree: 'U maakt een afspraak ten laatste aan de deur. U hebt geen afspraak? Dan registreren we u aan de kassa. Het kan gebeuren dat u 5min moet wachten eer u toegang verleend wordt.', // eslint-disable-line max-len
    paragraphFour: 'Een afspraak kan via e-mail of via telefoon. U geeft datum, tijdstip.',
    paragraphFive: 'Tot binnenkort en dank u om aan ons te denken!'
  },
  vacation_banner:{
    title:'We gaan er even tussenuit',
    message: 'We zijn gesloten van 25/10 tot en met 24/10'
  },
  nav: {
    contact: 'Contact',
    allCats: 'Alle producten',
    callUs: 'Bel ons',
    emailUs: 'Email ons',
    contactUs: 'Contacteer us',
  },
  product: {
    notices: 'Opgelet!',
    pricesMayVary: 'Actuele prijzen kunnen in de winkel variëren.',
    mayNotBeAvailable: 'Gelieve ons te contacteren indien u zeker wenst te zijn dat een product voorradig is.',
    mayNotBeAvailable2: 'Indien u een artikel of maat niet in onze rekken vindt kunt u dit steeds vragen aan de kassa.',
    description: 'Omschrijving',
    specifications: 'Specificaties',
    morePicture: '1 extra foto',
    morePictures: '{cnt} extra foto\'s',
  },
  category: {
    subcatFilter: 'Filter',
    openSubcatFilters: 'Filters openen',
  },
  footer: {
    address: 'Adres',
    contact: 'Contact',
    addressInstructions: 'Brusselse Ring Afrit 10 Asse',
    copyright: '{years} Stockoma',
    cookieNotice: 'We gebruiken cookies om je de best mogelijke ervaring te bieden. Door verder te surfen op onze site gaat u akkoord met ons gebruik van cookies.', // eslint-disable-line
    cookieNoticeClose: 'Sluiten',
    paymentMethods: 'Betaalwijzen',
    cash: 'Cash',
    bancontactOrCcreditCard: 'Bancontact of kredietkaart vanaf €{acceptBancontactFrom}',
    connect: 'Connecteer met ons',
    spotlight: 'In de schijnwerper',
    weaponLicenseNumber: 'Wapen licentie',
    vatNumber: 'BTW nummer',
    privacyStatement: 'Privacyverklaring',
    cookiePolicy: 'Cookie beleid',
  },
  openingHours: {
    monday: 'Maandag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag',
    thursday: 'Donderdag',
    friday: 'Vrijdag',
    saturday: 'Zaterdag',
    sunday: 'Zondag',
    closed: 'Gesloten',
    openingHours: 'Openingsuren',
    newopeningHours: 'Nieuwe openingsuren vanaf mei'
  },
  catToolbar: {
    popularity: 'Populariteit',
    sortPriceAsc: 'Prijs (Laag > Hoog)',
    sortPriceDesc: 'Prijs (Hoog > Laag)',
    sortNameAsc: 'Naam (A > Z)',
    sortNameDesc: 'Naam (Z > A)',
  },
  search: {
    search: 'Zoeken',
    sortBy: 'Sorteren op',
    pageSize: 'Toon',
    pageTitle: 'Zoek resultaten',
    category: 'Categorie',
    product: 'Product',
    moreResults: 'Meer resultaten'
  },
  pagination: {
    next: 'Volgende',
    previous: 'Vorige'
  },
  toastrSuccessTitle: 'Mucho succes',
  toastrFailureTitle: 'Oh noes!',
  toastrFailure: 'Boem! Crash! Kapot!',
  notFound: {
    title: '404',
    msg: 'Deze pagina kon niet gevonden worden!',
    prodMsg: 'Dit product kon niet gevonden worden!',
    catMsg: 'Deze categorie kon niet gevonden worden!',
    catalogInstead: 'Door onze catalogus bladeren',
  },
};
