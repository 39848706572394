import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util.js';
import {Icon} from '../../controls.js';
import {setLanguage} from '../../../actions/appActions.js';

class LanguageChangerComponent extends Component {
  static propTypes = {
    lang: PropTypes.lang,
    setLanguage: PropTypes.func.isRequired,
    languages: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    hideLabel: PropTypes.bool,
  }

  constructor() {
    super();
    this.state = {isOpen: false};
  }

  onChangeLanguage(lang) {
    this.setState({isOpen: false});
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(lang);
    }
    this.props.setLanguage(lang);
  }

  onOpenMenu(e) {
    this.setState({isOpen: !this.state.isOpen});
    e.preventDefault();
  }

  render() {
    const languages = this.props.languages;

    return (
      <div className="language-changer">
        <a onClick={this.onOpenMenu.bind(this)} className="clickable" href="change-language">
          <div className="media">
            <img src={'/images/' + this.props.lang + '.png'} alt="" height={16} className="d-flex align-self-center me-3" />
            {!this.props.hideLabel && (
              <div className="media-body language-changer-text" style={{marginLeft: -5}}>
                <span>{languages[this.props.lang]}</span>
                <Icon fa="fa-chevron-down d-inline d-sm-none" style={{marginLeft: 10}} />
              </div>
            )}
          </div>
        </a>

        {this.state.isOpen ? (
          <ul className="picker">
            {Object.keys(languages).filter(lang => lang !== this.props.lang).map(lang => {
              return (
                <li key={lang} onClick={() => this.onChangeLanguage(lang)} className="clickable">
                  <div className="media">
                    {!this.props.hideLabel && <Icon fa="fa-angle-double-right" style={{marginTop: 4, marginRight: 10}}/>}
                    <img src={'/images/'+ lang + '.png'} alt="" height={12} className="d-flex align-self-center me-3" />
                    {!this.props.hideLabel && (
                      <div className="media-body" style={{marginLeft: -5, minWidth: 80}}>
                        <span>{languages[lang]}</span>
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({lang: state.t.lang, languages: state.config.languages});
export const LanguageChanger = connect(mapStateToProps, {setLanguage})(LanguageChangerComponent);
