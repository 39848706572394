import {ActionTypes} from './ActionTypes.js';
import {addNotification as notify} from 'reapop';

// TODO: remove reapop and replace with something simple

// https://github.com/LouisBarranqueiro/reapop/blob/master/docs/api.md#customize-default-values-for-notifications
export const notifySettings = {
  status: 'success',
  position: 'br',
  dismissible: true,
  dismissAfter: 2000,
  closeButton: false,
  allowHTML: false,
};

export function success(msg) {
  return notify({
    status: 'success',
    title: 'toastrSuccessTitle',
    message: msg
  });
}

export function failure(title, msg, timeout) {
  return notify({
    status: 'error',
    position: 'tc',
    dismissAfter: timeout || 4000,
    title: title || 'toastrFailureTitle',
    message: msg || 'toastrFailure',
  });
}



export function changeViewport() {
  return {type: ActionTypes.VIEWPORT_RESIZE, payload: null};
}



export function setLanguage(lang = null) {
  return {type: ActionTypes.SET_LANGUAGE, payload: lang};
}



export function toggleMobileMenu() {
  return {type: ActionTypes.TOGGLE_MENU};
}



export function setCategorySortBy(sortKey) {
  return {type: ActionTypes.CATEGORY_SORT_CHANGED, payload: sortKey};
}

export function setCategoryPageSize(pageSize) {
  return {type: ActionTypes.CATEGORY_PAGESIZE_CHANGED, payload: pageSize};
}
