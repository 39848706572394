import {ActionTypes} from './ActionTypes.js';
import {httpClient} from '../util/httpClient.js';
//import {MapCategoryToSearchResult, MapProductToSearchResult} from '../util/mapper.js';


const isProd = process.env.NODE_ENV === 'production';
const getUrl = resource => {
  const endpoint = isProd ? 'api' : 'data';
  return `/${endpoint}/${resource}.json`;
};


function fetchCategories() {
  return dispatch => {
    return httpClient.get(getUrl('categories')).then(data => {
      dispatch({type: ActionTypes.CATEGORIES_FETCHED, payload: data});
    });
  };
}


function fetchProducts() {
  return dispatch => {
    return httpClient.get(getUrl('products')).then(data => {
      dispatch({type: ActionTypes.PRODUCTS_FETCHED, payload: data});
    });
  };
}


/*
function fetchProductViews() {
  return dispatch => {
    return httpClient.get(getUrl('products/Views')).then(data => {
      dispatch({type: ActionTypes.PRODUCTS_VIEWS_FETCHED, payload: data});
    });
  };
}*/

function fetchConfig() {
  return dispatch => {
    return httpClient.get(getUrl('config')).then(data => {
      dispatch({type: ActionTypes.CONFIG_FETCHED, payload: data});
    });
  };
}



export function initialLoad() {
  return dispatch => Promise.all([
    dispatch(fetchCategories()),
    dispatch(fetchProducts()),
    dispatch(fetchConfig()),
    //dispatch(fetchSearchResult())
  ]).then(() => {
    dispatch({type: ActionTypes.INITIAL_LOAD});
    // TODO: Product views
    // dispatch(fetchProductViews());
  });
}
