// @flow
import {Translation} from './TranslationModel.js';
import {ProductImageInfo} from './ProductImageInfoModel.js';

export class Product {
  id: string;
  price: number;
  // images: Array<ProductImageInfo>;
  imageCount: number;
  views: number;

  _name: Translation;
  _desc: Translation;
  _brand: Translation;
  _type: Translation;

  categories: Array<string>;
  specs: ?Array<ProductSpec>;
  options: Array<string>;

  get slug(): string {
    return '/product/' + this._name.slug() + '/' + this.id + '/' + this.categories[0];
  }

  get name(): string {
    return this._name.get();
  }
  get desc(): string {
    return this._desc.get();
  }
  get brand(): string {
    return this._brand.get();
  }
  get type(): string {
    return this._type.get();
  }

  constructor(json: any) {
    this.id = json.id;
    this.price = json.price || 0;
    // this.images = json.images ? json.images.map(img => new ProductImageInfo(this, img)) : [];
    this.imageCount = json.imageCount || 0;
    this.views = json.views || 0;

    this._name = new Translation(json.name);
    this._desc = new Translation(json.desc);
    this._brand = new Translation(json.brand);
    this._type = new Translation(json.type);

    this.categories = json.categories || [];
    this.specs = json.specs ? json.specs.map(x => new ProductSpec(x)) : null;
    this.options = json.options;
  }

  getImageUrl(imageIndex: number = 1): ProductImageInfo {
    //this.path = `${process.env.REACT_APP_PRODUCT_IMAGES}/${product.id}_${json.count}.${json.type}`;
    return {
      path: `${process.env.REACT_APP_PRODUCT_IMAGES}/${this.id}_${imageIndex}.png`,
      count: imageIndex,
    };
    //return this.images.find(img => img.count === imageIndex);
  }

  getImageUrls(): Array<ProductImageInfo> {
    var images = [];
    for (var i = 1; i <= this.imageCount; i++) {
      images.push({
        path: `${process.env.REACT_APP_PRODUCT_IMAGES}/${this.id}_${i}.png`,
        count: i,
      });
    }
    return images;
    // return this.images;
  }
}

class ProductSpec {
  specId: string;
  options: Array<string>;

  constructor(json) {
    Object.assign(this, json);
  }
}
