// React & third-party
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';


export function useGaTracker (gaKey) {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  if (process.env.NODE_ENV !== 'production') {
    console.log('Google Analitics - is not initilized since current enviroment is not production.'); // eslint-disable-line no-console
    return;
  }

  useEffect(() => {
    setInitialized(true);
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize(gaKey);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
}
