import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {PropTypes, cn} from '../../util.js';
import {OpeningHours, CookieNotice, Address, Contact} from './controls/controls.js';
import {GoogleMaps, FacebookPost} from '../controls.js';

import logo from '../../img/logo-classic.png';
import {Payment} from './controls/PaymentMethods';
import {Connect} from './controls/Connect';


class FooterComponent extends Component {
  static propTypes = {
    config: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    t: PropTypes.any.isRequired,
  }

  render() {
    const {t} = this.props;
    return (
      <section className="footer" style={{paddingTop: 30}}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <Connect className="footer-section"/>
            </div>
          </div>
          <div className="row">
            {this.props.app.deviceSameOrLargerThan('lg') &&
              <div className="col-12 col-md-4">
                <GoogleMaps style={{marginTop: 30}}/>
              </div>
            }
            <div className={cn('col-12 col-lg-4 col-md-6', this.props.app.deviceSameOrLargerThan('md') ? 'text-center' : '')}>
              <div style={{display: 'inline-block', 'textAlign': 'left'}}>
                <Address className="footer-section"/>
                <OpeningHours className="footer-section"/>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6">
              <div>
                <div style={{display: 'inline-block'}}>
                  <Contact className="footer-section"/>
                </div>
              </div>
              <Payment className="footer-section"/>
            </div>
          </div>

          <div className="row justify-content-md-center" style={{marginTop: 10}}>
            <div className="col-md-6 col-sm-8">
              <strong className="footer-title">{t('footer.spotlight')}</strong>
              <FacebookPost />
            </div>
          </div>

          <div className="row" style={{marginTop: 10}}>
            <div className="col-sm-12">
              <hr />
            </div>
            <div className="col-sm-12 text-center">
              <CopyrightNotice t={t}/>
            </div>
          </div>
        </div>
        <CookieNotice/>
      </section>
    );
  }
}

export const Footer = connect(state => ({config: state.config, t: state.t, app: state.app}))(FooterComponent);

const CopyrightNotice = ({t}) => {
  const years = '1951 - ' + new Date().getFullYear();
  return (
    <div style={{display: 'inline-block'}}>
      <div className="media">
        <img src={logo} className="img-fluid" style={{maxWidth: 60}} alt="Stockoma logo"/>
        <div className="media-body footer-text" style={{marginLeft: 10}}>
          <div style={{display: 'inline-block'}}>© {t('footer.copyright', {years})}</div>
          <ExtraInfo/>
        </div>
      </div>
    </div>
  );
};
CopyrightNotice.propTypes = {t: PropTypes.any.isRequired};

class ExtraInfoComponent extends Component {
  static propTypes = {
    config: PropTypes.any.isRequired,
    t: PropTypes.any.isRequired,
  }

  render() {
    const {t, config} = this.props;
    return [
      <div key="weapon">{t('footer.weaponLicenseNumber')}: {config.weaponLicenseNumber}</div>,
      <div key="company">{t('footer.vatNumber')}: {config.companyNumber}</div>,
      <div key="privacy-statement" style={{fontSize: 13, marginBottom: 6}}>
        <Link to={'/privacy-statement'} onClick={() => window.scrollTo(0, 0)}>
          {t('footer.privacyStatement')}
        </Link>
        &nbsp; - &nbsp;
        <Link to={'/cookie-policy'} onClick={() => window.scrollTo(0, 0)}>
          {t('footer.cookiePolicy')}
        </Link>
      </div>,
    ];
  }
}

const ExtraInfo = connect(state => ({t: state.t, config: state.config}))(ExtraInfoComponent);
