import {store} from './store.js';
import {initialLoad, changeViewport} from '../actions/index.js';


// Load extra css - if you do not have this file, see README.
import '../styles/index.css';
import 'font-awesome/css/font-awesome.css';


// Fetch data from the db
store.dispatch(initialLoad());

window.addEventListener('resize', () => {
  store.dispatch(changeViewport());
});
