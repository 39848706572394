export const trans = {
  facebookLanguage: 'fr_FR',
  title: 'Stockoma',
  categories: 'Categories',
  catalog: 'Catalogue',
  corona: {
    order: 'Order',
    mailUs: 'Mail us your wishlist and have your goodies delivered at your home or come pick them up!',

    title: 'Order from our catalog',
    wayOfWorking: 'Send us your order via email and we\'ll confirm the availability of the goods as soon as possible.',
    wayOfWorking2: 'Payment and shipping options will be communicated as your order comes in.',
    pickupTitle: 'Pickup service',
    pickup: 'We prepare your packet and let you know when you can come pick it up at our store.',
    deliveryTitle: 'Delivery service',
    delivery: 'All deliveries with GLS: €8,5 shipping costs in Belgium.',
    noWeapons: 'Attention! Airguns, weapons etc cannot be ordered.',
    footer: 'ou utilisez notre',
  },
  corona_banner:{
    title: 'Des règles autour corona!',
    paragraphOne: 'De 27/03 jusqu`au 26/04 inclu changement de les horaires d`ouverture',
    paragraphTwo: '(de 10.00 - 12.00 et de 13.30 - 18.00) Sans obligation d\'achat!',
    paragraphThree: 'Vous prenez rendez-vous au plus tard le jour de la visite à la porte. Pas de rendez-vous = Registration a la caisse.',
    paragraphFour: 'Possible que vous devez patienter 5min. Vous pouvez fixer un rendez-vous par e-mail ou par téléphone. Vous passer la date, l\'heure.', // eslint-disable-line max-len
    paragraphFive: 'À bientôt et merci de nous rendre visite!'
  },
  vacation_banner:{
    title:'On fait une pauze',
    message: 'Nous sommes fermés du 25/10 jusqu\'au 29/10'
  },
  nav: {
    contact: 'Contact',
    allCats: 'Tous les produits',
    callUs: 'Appelle nous',
    emailUs: 'Écrivez-nous',
    contactUs: 'Contactez-nous',
  },
  product: {
    notices: 'Attention!',
    pricesMayVary: 'Changement de prix est possible en magasin.',
    mayNotBeAvailable: "Si vous voulez être sur qu'un article est en stock, contactez nous avant votre visite.", // eslint-disable-line
    mayNotBeAvailable2: "Vous ne trouvez pas un article / taille dans nos rayons, demandez-le à la caisse.", // eslint-disable-line
    description: 'Description',
    specifications: 'Caractéristiques',
    morePicture: '1 image en plus',
    morePictures: '{cnt} images en plus',
  },
  category: {
    subcatFilter: 'Filtrer',
    openSubcatFilters: 'Ouvrir les filtres',
  },
  footer: {
    address: 'Adresse',
    contact: 'Contact',
    addressInstructions: 'ring de BRXL sortie 10 Asse',
    copyright: '{years} Stockoma',
    cookieNotice: "En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies permettant d'améliorer votre expérience utilisateur.", // eslint-disable-line
    cookieNoticeClose: 'Fermer',
    paymentMethods: 'Méthodes de paiement',
    cash: 'Contant',
    bancontactOrCcreditCard: 'Bancontact ou carte de credite dès  €{acceptBancontactFrom}',
    connect: 'Rester connecté',
    spotlight: 'À l\'honneur',
    weaponLicenseNumber: 'Numéro de licence d\'arme',
    vatNumber: 'Numéro de TVA',
    privacyStatement: 'Déclaration de confidentialité',
    cookiePolicy: 'Cookie Policy',
  },
  openingHours: {
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    closed: 'Fermée',
    openingHours: 'Heures d \'ouverture',
    newopeningHours: 'Nouvelles heures d’ouverture à partir de mai'
  },
  catToolbar: {
    popularity: 'Popularité',
    sortPriceAsc: 'Pris (Bas > Haute)',
    sortPriceDesc: 'Pris (Haute > Bas)',
    sortNameAsc: 'Nom (A > Z)',
    sortNameDesc: 'Nom (Z > A)',
  },
  search: {
    search: 'Chercher',
    sortBy: 'Trier par',
    pageSize: 'Afficher',
    pageTitle: 'Résultats de recherche',
    category: 'Catégorie',
    product: 'Produit',
    moreResults: 'Plus de résultats'
  },
  pagination: {
    next: 'Suivante',
    previous: 'Prev'
  },
  toastrSuccessTitle: 'Mucho succes',
  toastrFailureTitle: 'Oh noes!',
  toastrFailure: 'Boem! Crash! Cassé!',
  notFound: {
    title: '404',
    msg: 'Cette page n\'a pas pu être trouvée!',
    prodMsg: 'Ce produit n\'a pas pu être trouvée!',
    catMsg: 'Cette catégorie n\'a pas pu être trouvée!',
    catalogInstead: 'Parcourir notre catalogue',
  },
};
