import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'querystring';
import { PropTypes, connect } from '../../util';
import { ApplySearch } from '../../util/searchHelper';
import { MapCategoryToSearchResult, MapProductToSearchResult } from '../../util/mapper';
import { SearchPagination } from './controls/SearchPagination';

import Masonry from 'react-masonry-component';

const pageSize = 10;

export class SearchPageComponent extends Component {

  SearchItems = [];

  static propTypes = {
    t: PropTypes.any.isRequired,
    products: PropTypes.ProductModelList.isRequired,
    categories: PropTypes.CategoryModelList.isRequired,
    location: PropTypes.any.isRequired,
    currentPage: PropTypes.number
  }

  constructor(props) {
    super(props);


    this.state = {
      searchValue: '',
      currentPage: 0
    };
  }

  setItems() {
    var itemsArray = [];

    if (this.props.products.length > 0) {
      this.props.products.forEach(prod => itemsArray.push(MapProductToSearchResult(prod)));
    }

    if (this.props.categories.length > 0) {
      this.props.categories.forEach(cat => itemsArray.push(MapCategoryToSearchResult(cat)));
    }

    this.SearchItems = itemsArray;
  }

  componentDidMount() {
    var query = queryString.parse(this.props.location.search);
    this.setState({ currentPage: query.page || 0 });
    this.setItems();
  }

  componentDidUpdate() {
    this.setItems();
  }

  render() {
    const t = this.props.t;

    var query = queryString.parse(this.props.location.search);
    var searchResults = ApplySearch(this.SearchItems, query['?searchValue']);

    var renderProductLink = (item) => {
      return (
        <Link key={item.key} className="card" to={item.path}>
          { item.price !== 0 &&
          <span className="card-badge-price">{item.price} €</span>
          }
          <img src={item.image} alt="" className="img img-fluid mx-auto d-block"/>
          <div className="card-name">{item.title.get()}</div>
        </Link>
      );
    };

    var renderCategoryLink = (item) => {
      return (
        <Link key={item.key} className="card" to={item.path}>
          <span className="card-badge-category">{t('search.category')}</span>
          <img src={item.image} alt="" className="img img-fluid mx-auto d-block"/>
          <div className="card-name">{item.title.get()}</div>
        </Link>
      );
    };

    var pageResultLength = Math.ceil(searchResults.length / pageSize);
    var results = searchResults.slice((this.state.currentPage * pageSize), ((this.state.currentPage * pageSize) + pageSize));

    const masonryOptions = {
      transitionDuration: 0
    };

    return (
      <div className="search-page">
        <h1>{t('search.pageTitle')}</h1>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <Masonry
                className="card-list"
                options={masonryOptions}
                disableImagesLoaded={false}
                updateOnEachImageLoad={false} >
                {results.map((item) => item.type === 'Product' ? renderProductLink(item) : renderCategoryLink(item)
                )}
              </Masonry>
              {pageResultLength > 1 &&
                <SearchPagination
                  pageAmount={pageResultLength}
                  currentPage={this.props.currentPage}
                  onClick={(event, page) => this.setState({ currentPage: page })} />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ products: state.prods, categories: state.cats, t: state.t });
export const SearchPage = connect(mapStateToProps)(SearchPageComponent);

