// React & third party
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
// Third party
import {store} from '../src/util/store.js';
// Custom App
import App from './App.js';
// Custom actions
import {setLanguage} from '../src/actions/appActions.js';

import './util/startup.js';

store.dispatch(setLanguage());

// <Router onUpdate={() => window.scrollTo(0, 0)}>
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
