import { Category } from '../models/CategoryModel';
import { Product } from '../models/ProductModel';
import { SearchResult } from '../models/SearchResultModel';

export function MapProductToSearchResult(object: Product): SearchResult {
  var result = new SearchResult();
  result.title = object._name;
  result.image = object.getImageUrl().path;
  result.type = 'Product';
  result.price = object.price;
  result.path = `/product/${result.title.slug()}/${object.id}`;
  if (object.categories) {
    result.path = `${result.path}/${object.categories[0]}`;
  }

  result.key = object.id;
  result.description = object._desc;
  return result;
}

export function MapCategoryToSearchResult(object: Category): SearchResult {
  var result = new SearchResult();
  result.title = object._name;
  result.image = object.mainCategoryImg ? object.mainCategoryImg : '/images/Stockoma_placeholder.png';
  result.type = 'Category';
  result.path = `/category/${result.title.slug()}/${object.id}`;
  result.key = object.id;
  result.description = '';

  return result;
}